import store from '@/store'

export default [
  {
    header: 'sidebar.headers.orders',
  },
  {
    title: 'modules.orders.p_c',
    route: 'orders.list',
    // icon: 'ShoppingCartIcon',
    iconMaterial: 'shopping_cart',
    tag: parseInt(store.state.appConfig.layout.menu.counters.orders, 10) || '',
    tagVariant: 'light-primary',
    tagId: 'menu_orders_count',
  },
  {
    title: 'sidebar.abandoned_carts',
    route: { name: 'abandonedCarts.list', params: { step: 'products' } },
    // icon: 'ShoppingBagIcon',
    iconMaterial: 'production_quantity_limits',
    tag: '✨',
    tagVariant: 'transparent',
  },
]
