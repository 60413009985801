// import { shop } from '@core/utils/utils'
//
// const subscription = shop()?.subscription

export default [
  {
    header: 'sidebar.headers.control',
  },
  {
    title: 'sidebar.shipping_methods',
    route: 'shippingSettings',
    icon: 'TruckIcon',
  },
  {
    title: 'sidebar.payment_methods',
    route: 'paymentSettings',
    icon: 'CreditCardIcon',
  },

  // ...canUseFeature('app_marketplace')
  //   ? [
  {
    title: 'sidebar.apps_market',
    route: 'apps-index',
    icon: 'ZapIcon',
    // tag: 'New',
    tagVariant: 'light-warning',
  },
  // ] : [],

  {
    title: 'sidebar.layout_settings',
    route: 'layoutSettings',
    iconMaterial: 'table_edit',
    icon: 'SettingsIcon',
    // tag: 'New',
    tagVariant: 'light-primary',
  },
  {
    title: 'sidebar.settings',
    route: { name: 'generalSettings', params: { category: 'store', subcategory: 'general' } },
    icon: 'SettingsIcon',
  },
  // {
  //   title: 'sidebar.settings',
  //   route: null,
  //   icon: 'SettingsIcon',
  //   children: [
  //     {
  //       title: 'sidebar.settings_children.layout',
  //       route: 'card-basic',
  //     },
  //     {
  //       title: 'sidebar.settings_children.accounts',
  //       route: 'card-basic',
  //     },
  //     {
  //       title: 'sidebar.settings_children.cart',
  //       route: 'card-advance',
  //     },
  //     {
  //       title: 'sidebar.settings_children.email',
  //       href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //     },
  //   ],
  // },
  {
    title: 'sidebar.subscriptions',
    route: 'subscription',
    icon: 'CreditCardIcon',
    // tag: `subscription_statuses.${subscription.status || 'ACTV'}`,
    // tagVariant: `light-${subscription.status_class || 'success'}`,
  },
]
